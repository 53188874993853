








































import { useBranch } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { BranchWarehouseCreateRequestDto } from "@/models/interface/branch";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Prop, Ref, Watch } from "vue-property-decorator";

@Component
export default class FormModals extends Mixins(MNotificationVue) {
  @Prop({ type: Boolean, default: false, required: false })
  visible!: boolean;

  @Ref("formModel")
  formModel!: FormModel;

  @Prop({ type: String, required: false, default: "" })
  branchId!: string;

  form: BranchWarehouseCreateRequestDto = {
    address: "",
    code: "",
    name: "",
  };

  formRules = {
    address: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    code: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    name: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  loading = false;

  get isUpdate(): boolean {
    return !!this.branchId;
  }

  get title(): string {
    const lblEdit = this.$t("lbl_edit_x", {
      x: this.$t("lbl_branch"),
    }).toString();
    const lblCreate = this.$t("lbl_create_x", {
      x: this.$t("lbl_branch"),
    }).toString();

    return this.isUpdate ? lblEdit : lblCreate;
  }

  @Watch("branchId")
  onChangeBranch(): void {
    if (!this.branchId) return;
    this.getDetail(this.branchId);
  }

  getDetail(branchId: string): void {
    const { findById } = useBranch();
    findById(branchId).then(res => {
      this.form = res;
    });
  }

  handleCancel(): void {
    this.formModel.resetFields();
    this.$emit("close");
  }

  handleSuccess(): void {
    this.formModel.resetFields();
    this.$emit("success");
  }

  validateForm(): void {
    this.formModel.validate(valid => {
      if (!valid) return;

      if (this.isUpdate) {
        this.handleUpdate(this.branchId, this.form);
      } else {
        this.handleCreate();
      }
    });
  }

  handleUpdate(branchId: string, form: BranchWarehouseCreateRequestDto): void {
    const { update } = useBranch();
    update(branchId, form).then(({ name }) => {
      this.showNotifSuccess("notif_update_success", {
        documentNumber: name,
      });
      this.handleSuccess();
    });
  }

  handleCreate(): void {
    const { create } = useBranch();
    this.loading = true;
    create(this.form)
      .then(({ name }) => {
        this.showNotifSuccess("notif_create_success", {
          documentNumber: name,
        });
        this.handleSuccess();
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
